import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../src/environments/environment';
import { UserDataService } from './userData.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    private afAuth: AngularFireAuth,
  ) {}

  get authState$(): Observable<any> {
    return this.afAuth.authState;
  }

  async login(email: string, password: string): Promise<any> {
    const result = await this.afAuth.signInWithEmailAndPassword(email, password);
    return result;
  }

  async logOut(): Promise<any> {
    // this.userDataService.stopListenUserData();
    this.afAuth.signOut().then(() => {
      window.location.href = '';
    });
  }

  recoberyPassword(email:any): Promise<any> {
    return this.afAuth.sendPasswordResetEmail(email);
  }
}
